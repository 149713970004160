<template>
  <div class="total">
    <div class="total__title">
      <span v-text="'Результаты'"/>
    </div>
    <div class="total__body wrap">
      <apex-chart
        ref="chart"
        type="pie"
        :options="chartOptions"
        :series="series"
        :height="size.height"
        :width="size.width"
      />
      <h1 v-text="totalEmission" />
      <div class="total__body__actions">
        <carbon-button
          text="На главную"
          @click="push('/')"
          :vue-bind="{ color: '#4CAF50' }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CarbonButton from '../common/components/CarbonButton';

export default {
  name: 'Total',
  components: {
    CarbonButton,
  },
  data: () => ({
    size: {
      height: '0px',
      width: '0px',
    },
    mapImages: [
      {
        id: 'Пища',
        image: '/images/food.png',
      },
      {
        id: 'Жилье',
        image: '/images/home.png',
      },
      {
        id: 'Логистика',
        image: '/images/logistic.png',
      },
      {
        id: 'Транспорт',
        image: '/images/transport.png',
      },
      {
        id: 'Путешествия',
        image: '/images/travel.png',
      },
    ],
  }),
  computed: {
    totalEmission() {
      const total = (this.total || {}).total || 0;
      const totalTons = Number((Number(total) / 1000).toFixed(1));
      return `Всего ${totalTons} тонн`;
    },
    total() {
      return this.$store.state.total;
    },
    series() {
      if (this.total) {
        return [
          this.total.food || 0,
          this.total.house.total || 0,
          this.total.logistics.total || 0,
          this.total.transport || 0,
          this.total.travel.total || 0,
        ];
      }
      return [0.1, 0.1, 0.1, 0.1, 0.1];
    },
    chartOptions() {
      return {
        chart: {
          size: '100%',
          type: 'pie',
        },
        labels: ['Пища', 'Жилье', 'Логистика', 'Транспорт', 'Путешествия'],
        responsive: [
          {
            breakpoint: 640,
            options: {

              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      };
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize, { passive: true });
  },
  methods: {
    onResize() {
      this.$nextTick(() => {
        const delta = this.$vuetify.breakpoint.mobile ? 0 : 200;
        if (this.$el) {
          const body = this.$el.querySelector('.total__body');
          this.size = {
            height: `${body.clientHeight - delta}px`,
            width: `${body.clientWidth - delta}px`,
          };
        }
      });
    },
    push(path) {
      window.open(path, '_self');
      // this.$router.push(path).catch(() => {

      // });
    },
  },
};
</script>

<style lang="sass">
  .total
    display: flex
    flex-direction: column
    align-items: center
    color: #4caf50
    overflow-y: auto
    overflow-x: hidden
    &__title
      font-size: 45px
      font-weight: bold
    &__body
      display: flex
      flex-direction: column
      align-items: center
      justify-content: space-around
      height: 100%
      width: 100%
</style>
